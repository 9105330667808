<template>
  <div class="login">
    <div class="container login-wrapper">
      <div class="row align-items-center">
        <div class="col-md-5">
          <img src="../assets/login-img.svg" alt="" />
        </div>
        <div class="col-md-7">
          <h3>Login</h3>
          <form @submit.prevent="loginWithOtp">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-lab el">Email</label>
              <input
                type="text"
                class="form-control"
                id="email"
                v-model="userName"
                aria-describedby="emailHelp"
              />
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.userName.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="mb-3" v-if="showOtpField == true">
              <label for="exampleInputphone" class="form-lab el">OTP</label>
              <input
                type="text"
                class="form-control"
                id="otp"
                v-model="otp"
                aria-describedby="emailHelp"
              />
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.otp.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="mb-3 action-div">
              <span v-if="this.loginType == 'Nest'"
                >or try with
                <router-link
                  :to="{ name: 'otpPhoneLogin', query: { name: 'Nest' } }"
                  >Phone</router-link
                ></span
              >
              <span v-else
                >or try with
                <router-link
                  :to="{ name: 'otpPhoneLogin', query: { name: 'Pupa' } }"
                  >Phone</router-link
                ></span
              >
            </div>
            <div class="text-right row">
              <div class="col">
                <button
                  type="submit"
                  :disabled="isAuthenticating == true"
                  class="btn cta-primary"
                >
                  <span
                    v-if="isAuthenticating == false && showOtpField == false"
                  >
                    Get OTP
                  </span>
                  <span
                    v-else-if="
                      // eslint-disable-next-line prettier/prettier
                      isAuthenticating == false && showOtpField == true"
                  >
                    Login
                  </span>
                  <span v-else>
                    <circular-loader></circular-loader>
                  </span>
                </button>
              </div>
              <div
                class="col"
                v-if="isAuthenticating == false && showOtpField == true"
              >
                <button
                  @click="resendOtp()"
                  :disabled="isResending == true"
                  class="btn cta-primary"
                >
                  <span v-if="isResending == false">
                    Resend OTP
                  </span>
                  <span v-else>
                    <circular-loader></circular-loader>
                  </span>
                </button>
              </div>
            </div>
            <div class="mb-3 action-div">
              <span v-if="this.loginType == 'Nest'"
                >Login with
                <router-link :to="{ name: 'login', query: { name: 'Nest' } }">
                  Password</router-link
                ></span
              >
              <span v-else
                >Login with
                <router-link :to="{ name: 'login', query: { name: 'Pupa' } }">
                  Password</router-link
                ></span
              >
              <span v-if="this.loginType == 'Nest'"
                >Don’t have an account
                <router-link
                  :to="{ name: 'nestRegister', query: { name: 'Nest' } }"
                  >Register</router-link
                ></span
              >
              <span v-else
                >Don’t have an account
                <router-link :to="{ name: 'register', query: { name: 'Pupa' } }"
                  >Register</router-link
                ></span
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import AuthService from "@/services/AuthService";
import CircularLoader from "@/components/loaders/CircularLoader";
import "bootstrap";
import errorLog from "@/errorLog";
import { required, helpers } from "@vuelidate/validators";
export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  name: "OtpPhoneLogin",
  components: {
    CircularLoader
  },
  data() {
    return {
      authStatus: "",
      token: "",
      user: "",
      userName: "",
      loginType: this.$route.query.name,
      loginWithOTP: false,
      isAuthenticating: false,
      otp: "",
      showOtpField: false,
      isResending: false,
      errors: {}
    };
  },
  validations() {
    return {
      userName: {
        required: helpers.withMessage("Please enter email", required)
      },
      otp: {
        required: helpers.withMessage("Please enter otp", required)
      }
    };
  },
  methods: {
    loginWithOtp: function() {
      const data = {
        user_name: this.userName,
        otp: this.otp
      };
      this.v$.userName.$touch();
      if (this.showOtpField == true) {
        this.v$.otp.$touch();
      }
      if (this.v$.userName.$invalid) {
        return;
      }
      if (this.v$.otp.$invalid && this.showOtpField == true) {
        return;
      }
      this.isAuthenticating = true;
      AuthService.getOTP(data)
        .then(result => {
          this.isAuthenticating = false;
          if (result.data.status == "otp_send") {
            this.showOtpField = true;
            this.$toast.success(result.data.message);
          } else if (result.data.status == "login_success") {
            this.$toast.success(result.data.message);
            const userData = {
              token: result.data.token,
              user: result.data.user
            };
            this.$store.dispatch("studentAuth/authSuccess", userData);
            this.isAuthenticating = false;
            if (this.$store.state.studentAuth.nextUrl == "") {
              this.$router.push({
                name: "profile"
              });
            } else {
              this.$router.push(this.$store.state.studentAuth.nextUrl);
              this.$store.dispatch("studentAuth/updateNextUrl", "");
            }
          } else {
            this.$toast.error(result.data.message);
          }
        })
        .catch(error => {
          this.error = error;
          this.isAuthenticating = false;
        });
    },
    async resendOtp() {
      const data = {
        user_name: this.userName
      };
      this.isResending = true;
      await AuthService.getOTP(data)
        .then(result => {
          if (result.data.status == "otp_send") {
            this.$toast.success(result.data.message);
            this.isResending = false;
          }
        })
        .catch(error => {
          errorLog.log(error);
          this.isResending = false;
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/login.scss";
</style>
